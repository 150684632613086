const getIconFromType = (type) => {
  if (!type) return 'fa fa-folder';
  switch (type) {
    case 'image/jpeg':
    case 'image/vnd.microsoft.icon':
    case 'image/gif':
    case 'image/bmp':
    case 'image/webp':
    case 'image/tiff':
    case 'image/svg+xml':
    case 'image/png':
      return 'fa fa-picture-o';
		case 'audio/mpeg':
			return 'fa fa-file-audio-o';
    case 'application/pdf':
      return 'fa fa-file-pdf-o';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'fa fa-file-excel-o';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'fa fa-file-word-o';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'fa fa-file-powerpoint-o';
    default:
      return 'fa fa-file-o';
  }
};

export const getTypeFromMime = (type) => supportedMimeTypes[type]  || 'Unknown';

const supportedMimeTypes = {
	"application/vnd.hzn-3d-crossword": "3D Crossword Plugin",
	"video/3gpp": "3GP",
	"video/3gpp2": "3GP2",
	"application/vnd.mseq": "3GPP MSEQ File",
	"application/vnd.3m.post-it-notes": "3M Post It Notes",
	"application/vnd.3gpp.pic-bw-large": "3rd Generation Partnership Project - Pic Large",
	"application/vnd.3gpp.pic-bw-small": "3rd Generation Partnership Project - Pic Small",
	"application/vnd.3gpp.pic-bw-var": "3rd Generation Partnership Project - Pic Var",
	"application/vnd.3gpp2.tcap": "3rd Generation Partnership Project - Transaction Capabilities Application Part",
	"application/x-7z-compressed": "7-Zip",
	"application/x-abiword": "AbiWord",
	"application/x-ace-compressed": "Ace Archive",
	"application/vnd.americandynamics.acc": "Active Content Compression",
	"application/vnd.acucobol": "ACU Cobol",
	"application/vnd.acucorp": "ACU Cobol",
	"audio/adpcm": "Adaptive differential pulse-code modulation",
	"application/x-authorware-bin": "Adobe (Macropedia) Authorware - Binary File",
	"application/x-authorware-map": "Adobe (Macropedia) Authorware - Map",
	"application/x-authorware-seg": "Adobe (Macropedia) Authorware - Segment File",
	"application/vnd.adobe.air-application-installer-package+zip": "Adobe AIR Application",
	"application/x-shockwave-flash": "Adobe Flash",
	"application/vnd.adobe.fxp": "Adobe Flex Project",
	"application/pdf": "Adobe Portable Document Format",
	"application/vnd.cups-ppd": "Adobe PostScript Printer Description File Format",
	"application/x-director": "Adobe Shockwave Player",
	"application/vnd.adobe.xdp+xml": "Adobe XML Data Package",
	"application/vnd.adobe.xfdf": "Adobe XML Forms Data Format",
	"audio/x-aac": "Advanced Audio Coding (AAC)",
	"application/vnd.ahead.space": "Ahead AIR Application",
	"application/vnd.airzip.filesecure.azf": "AirZip FileSECURE",
	"application/vnd.airzip.filesecure.azs": "AirZip FileSECURE",
	"application/vnd.amazon.ebook": "Amazon Kindle eBook format",
	"application/vnd.amiga.ami": "AmigaDE",
	"application/andrew-inset": "Andrew Toolkit",
	"application/vnd.android.package-archive": "Android Package Archive",
	"application/vnd.anser-web-certificate-issue-initiation": "ANSER-WEB Terminal Client - Certificate Issue",
	"application/vnd.anser-web-funds-transfer-initiation": "ANSER-WEB Terminal Client - Web Funds Transfer",
	"application/vnd.antix.game-component": "Antix Game Player",
	"application/x-apple-diskimage": "Apple Disk Image",
	"application/vnd.apple.installer+xml": "Apple Installer Package",
	"application/applixware": "Applixware",
	"application/vnd.hhe.lesson-player": "Archipelago Lesson Player",
	"application/x-freearc": "Archive document - Multiple Fils Embedded",
	"application/vnd.aristanetworks.swi": "Arista Networks Software Image",
	"text/x-asm": "Assembler Source File",
	"application/atomcat+xml": "Atom Publishing Protocol",
	"application/atomsvc+xml": "Atom Publishing Protocol Service Document",
	"application/atom+xml": "Atom Syndication Format",
	"application/pkix-attr-cert": "Attribute Certificate",
	"audio/x-aiff": "Audio Interchange File Format",
	"video/x-msvideo": "Audio Video Interleave (AVI)",
	"application/vnd.audiograph": "Audiograph",
	"image/vnd.dxf": "AutoCAD DXF",
	"model/vnd.dwf": "Autodesk Design Web Format (DWF)",
	"image/avif": "AV1 Image File",
	"text/plain-bas": "BAS Partitur Format",
	"application/x-bcpio": "Binary CPIO Archive",
	"application/octet-stream": "Binary Data",
	"image/bmp": "Bitmap Image File",
	"application/x-bittorrent": "BitTorrent",
	"application/vnd.rim.cod": "Blackberry COD File",
	"application/vnd.blueice.multipass": "Blueice Research Multipass",
	"application/vnd.bmi": "BMI Drawing Data Interchange",
	"application/x-sh": "Bourne Shell Script",
	"image/prs.btif": "BTIF",
	"application/vnd.businessobjects": "BusinessObjects",
	"application/x-bzip": "Bzip Archive",
	"application/x-bzip2": "Bzip2 Archive",
	"application/x-csh": "C Shell Script",
	"text/x-c": "C Source File",
	"application/vnd.chemdraw+xml": "CambridgeSoft Chem Draw",
	"text/css": "Cascading Style Sheets (CSS)",
	"application/x-cdf": "CD Audio",
	"chemical/x-cdx": "ChemDraw eXchange file",
	"chemical/x-cml": "Chemical Markup Language",
	"chemical/x-csml": "Chemical Style Markup Language",
	"application/vnd.contact.cmsg": "CIM Database",
	"application/vnd.claymore": "Claymore Data Files",
	"application/vnd.clonk.c4group": "Clonk Game",
	"image/vnd.dvb.subtitle": "Close Captioning - Subtitle",
	"application/cdmi-capability": "Cloud Data Management Interface (CDMI) - Capability",
	"application/cdmi-container": "Cloud Data Management Interface (CDMI) - Contaimer",
	"application/cdmi-domain": "Cloud Data Management Interface (CDMI) - Domain",
	"application/cdmi-object": "Cloud Data Management Interface (CDMI) - Object",
	"application/cdmi-queue": "Cloud Data Management Interface (CDMI) - Queue",
	"application/vnd.cluetrust.cartomobile-config": "ClueTrust CartoMobile - Config",
	"application/vnd.cluetrust.cartomobile-config-pkg": "ClueTrust CartoMobile - Config Package",
	"image/x-cmu-raster": "CMU Image",
	"model/vnd.collada+xml": "COLLADA",
	"text/csv": "Comma-Seperated Values",
	"application/mac-compactpro": "Compact Pro",
	"application/vnd.wap.wmlc": "Compiled Wireless Markup Language (WMLC)",
	"image/cgm": "Computer Graphics Metafile",
	"x-conference/x-cooltalk": "CoolTalk",
	"image/x-cmx": "Corel Metafile Exchange (CMX)",
	"application/vnd.xara": "CorelXARA",
	"application/vnd.cosmocaller": "CosmoCaller",
	"application/x-cpio": "CPIO Archive",
	"application/vnd.crick.clicker": "CrickSoftware - Clicker",
	"application/vnd.crick.clicker.keyboard": "CrickSoftware - Clicker - Keyboard",
	"application/vnd.crick.clicker.palette": "CrickSoftware - Clicker - Palette",
	"application/vnd.crick.clicker.template": "CrickSoftware - Clicker - Template",
	"application/vnd.crick.clicker.wordbank": "CrickSoftware - Clicker - Wordbank",
	"application/vnd.criticaltools.wbs+xml": "Critical Tools - PERT Chart EXPERT",
	"application/vnd.rig.cryptonote": "CryptoNote",
	"chemical/x-cif": "Crystallographic Interchange Format",
	"chemical/x-cmdf": "CrystalMaker Data Format",
	"application/cu-seeme": "CU-SeeMe",
	"application/prs.cww": "CU-Writer",
	"text/vnd.curl": "Curl - Applet",
	"text/vnd.curl.dcurl": "Curl - Detached Applet",
	"text/vnd.curl.mcurl": "Curl - Manifest File",
	"text/vnd.curl.scurl": "Curl - Source Code",
	"application/vnd.curl.car": "CURL Applet",
	"application/vnd.curl.pcurl": "CURL Applet",
	"application/vnd.yellowriver-custom-menu": "CustomMenu",
	"application/dssc+der": "Data Structure for the Security Suitability of Cryptographic Algorithms",
	"application/dssc+xml": "Data Structure for the Security Suitability of Cryptographic Algorithms",
	"application/x-debian-package": "Debian Package",
	"audio/vnd.dece.audio": "DECE Audio",
	"image/vnd.dece.graphic": "DECE Graphic",
	"video/vnd.dece.hd": "DECE High Definition Video",
	"video/vnd.dece.mobile": "DECE Mobile Video",
	"video/vnd.uvvu.mp4": "DECE MP4",
	"video/vnd.dece.pd": "DECE PD Video",
	"video/vnd.dece.sd": "DECE SD Video",
	"video/vnd.dece.video": "DECE Video",
	"application/x-dvi": "Device Independent File Format (DVI)",
	"application/vnd.fdsn.seed": "Digital Siesmograph Networks - SEED Datafiles",
	"application/x-dtbook+xml": "Digital Talking Book",
	"application/x-dtbresource+xml": "Digital Talking Book - Resource File",
	"application/vnd.dvb.ait": "Digital Video Broadcasting",
	"application/vnd.dvb.service": "Digital Video Broadcasting",
	"audio/vnd.digital-winds": "Digital Winds Music",
	"image/vnd.djvu": "DjVu",
	"application/xml-dtd": "Document Type Definition",
	"application/vnd.dolby.mlp": "Dolby Meridian Lossless Packing",
	"application/x-doom": "Doom Video Game",
	"application/vnd.dpgraph": "DPGraph",
	"audio/vnd.dra": "DRA Audio",
	"application/vnd.dreamfactory": "DreamFactory",
	"audio/vnd.dts": "DTS Audio",
	"audio/vnd.dts.hd": "DTS High Definition Audio",
	"image/vnd.dwg": "DWG Drawing",
	"application/vnd.dynageo": "DynaGeo",
	"application/ecmascript": "ECMAScript",
	"application/vnd.ecowin.chart": "EcoWin Chart",
	"image/vnd.fujixerox.edmics-mmr": "EDMICS 2000",
	"image/vnd.fujixerox.edmics-rlc": "EDMICS 2000",
	"application/exi": "Efficient XML Interchange",
	"application/vnd.proteus.magazine": "EFI Proteus",
	"application/epub+zip": "Electronic Publication",
	"message/rfc822": "Email Message",
	"application/vnd.enliven": "Enliven Viewer",
	"application/vnd.is-xpr": "Express by Infoseek",
	"image/vnd.xiff": "eXtended Image File Format (XIFF)",
	"application/vnd.xfdl": "Extensible Forms Description Language",
	"application/emma+xml": "Extensible MultiModal Annotation",
	"application/vnd.ezpix-album": "EZPix Secure Photo Album",
	"application/vnd.ezpix-package": "EZPix Secure Photo Album",
	"image/vnd.fst": "FAST Search & Transfer ASA",
	"video/vnd.fvt": "FAST Search & Transfer ASA",
	"image/vnd.fastbidsheet": "FastBid Sheet",
	"application/vnd.denovo.fcselayout-link": "FCS Express Layout Link",
	"video/x-f4v": "Flash Video",
	"video/x-flv": "Flash Video",
	"image/vnd.fpx": "FlashPix",
	"image/vnd.net-fpx": "FlashPix",
	"text/vnd.fmi.flexstor": "FLEXSTOR",
	"video/x-fli": "FLI/FLC Animation Format",
	"application/vnd.fluxtime.clip": "FluxTime Clip",
	"application/vnd.fdf": "Forms Data Format",
	"text/x-fortran": "Fortran Source File",
	"application/vnd.mif": "FrameMaker Interchange Format",
	"application/vnd.framemaker": "FrameMaker Normal Format",
	"image/x-freehand": "FreeHand MX",
	"application/vnd.fsc.weblaunch": "Friendly Software Corporation",
	"application/vnd.frogans.fnc": "Frogans Player",
	"application/vnd.frogans.ltf": "Frogans Player",
	"application/vnd.fujixerox.ddd": "Fujitsu - Xerox 2D CAD Data",
	"application/vnd.fujixerox.docuworks": "Fujitsu - Xerox DocuWorks",
	"application/vnd.fujixerox.docuworks.binder": "Fujitsu - Xerox DocuWorks Binder",
	"application/vnd.fujitsu.oasys": "Fujitsu Oasys",
	"application/vnd.fujitsu.oasys2": "Fujitsu Oasys",
	"application/vnd.fujitsu.oasys3": "Fujitsu Oasys",
	"application/vnd.fujitsu.oasysgp": "Fujitsu Oasys",
	"application/vnd.fujitsu.oasysprs": "Fujitsu Oasys",
	"application/x-futuresplash": "FutureSplash Animator",
	"application/vnd.fuzzysheet": "FuzzySheet",
	"image/g3fax": "G3 Fax Image",
	"application/vnd.gmx": "GameMaker ActiveX",
	"model/vnd.gtw": "Gen-Trix Studio",
	"application/vnd.genomatix.tuxedo": "Genomatix Tuxedo Framework",
	"application/vnd.geogebra.file": "GeoGebra",
	"application/vnd.geogebra.tool": "GeoGebra",
	"model/vnd.gdl": "Geometric Description Language (GDL)",
	"application/vnd.geometry-explorer": "GeoMetry Explorer",
	"application/vnd.geonext": "GEONExT and JSXGraph",
	"application/vnd.geoplan": "GeoplanW",
	"application/vnd.geospace": "GeospacW",
	"application/x-font-ghostscript": "Ghostscript Font",
	"application/x-font-bdf": "Glyph Bitmap Distribution Format",
	"application/x-gtar": "GNU Tar Files",
	"application/x-texinfo": "GNU Texinfo Document",
	"application/x-gnumeric": "Gnumeric",
	"application/vnd.google-earth.kml+xml": "Google Earth - KML",
	"application/vnd.google-earth.kmz": "Google Earth - Zipped KML",
	"application/gpx+xml": "GPS eXchange Format",
	"application/vnd.grafeq": "GrafEq",
	"image/gif": "Graphics Interchange Format",
	"text/vnd.graphviz": "Graphviz",
	"application/vnd.groove-account": "Groove - Account",
	"application/vnd.groove-help": "Groove - Help",
	"application/vnd.groove-identity-message": "Groove - Identity Message",
	"application/vnd.groove-injector": "Groove - Injector",
	"application/vnd.groove-tool-message": "Groove - Tool Message",
	"application/vnd.groove-tool-template": "Groove - Tool Template",
	"application/vnd.groove-vcard": "Groove - Vcard",
	"application/gzip": "GZip",
	"video/h261": "H.261",
	"video/h263": "H.263",
	"video/h264": "H.264",
	"application/vnd.hp-hpid": "Hewlett Packard Instant Delivery",
	"application/vnd.hp-hps": "Hewlett-Packard's WebPrintSmart",
	"application/x-hdf": "Hierarchical Data Format",
	"audio/vnd.rip": "Hit'n'Mix",
	"application/vnd.hbci": "Homebanking Computer Interface (HBCI)",
	"application/vnd.hp-jlyt": "HP Indigo Digital Press - Job Layout Languate",
	"application/vnd.hp-pcl": "HP Printer Command Language",
	"application/vnd.hp-hpgl": "HP-GL/2 and HP RTL",
	"application/vnd.yamaha.hv-script": "HV Script",
	"application/vnd.yamaha.hv-dic": "HV Voice Dictionary",
	"application/vnd.yamaha.hv-voice": "HV Voice Parameter",
	"application/vnd.hydrostatix.sof-data": "Hydrostatix Master Suite",
	"application/hyperstudio": "Hyperstudio",
	"application/vnd.hal+xml": "Hypertext Application Language",
	"text/html": "HyperText Markup Language (HTML)",
	"application/vnd.ibm.rights-management": "IBM DB2 Rights Manager",
	"application/vnd.ibm.secure-container": "IBM Electronic Media Management System - Secure Container",
	"text/calendar": "iCalendar",
	"application/vnd.iccprofile": "ICC profile",
	"image/x-icon": "Icon Image",
	"application/vnd.igloader": "igLoader",
	"image/ief": "Image Exchange Format",
	"application/vnd.immervision-ivp": "ImmerVision PURE Players",
	"application/vnd.immervision-ivu": "ImmerVision PURE Players",
	"application/reginfo+xml": "IMS Networks",
	"text/vnd.in3d.3dml": "In3D - 3DML",
	"text/vnd.in3d.spot": "In3D - 3DML",
	"model/iges": "Initial Graphics Exchange Specification (IGES)",
	"application/vnd.intergeo": "Interactive Geometry Software",
	"application/vnd.cinderella": "Interactive Geometry Software Cinderella",
	"application/vnd.intercon.formnet": "Intercon FormNet",
	"application/vnd.isac.fcs": "International Society for Advancement of Cytometry",
	"application/ipfix": "Internet Protocol Flow Information Export",
	"application/pkix-cert": "Internet Public Key Infrastructure - Certificate",
	"application/pkixcmp": "Internet Public Key Infrastructure - Certificate Management Protocole",
	"application/pkix-crl": "Internet Public Key Infrastructure - Certificate Revocation Lists",
	"application/pkix-pkipath": "Internet Public Key Infrastructure - Certification Path",
	"application/vnd.insors.igm": "IOCOM Visimeet",
	"application/vnd.ipunplugged.rcprofile": "IP Unplugged Roaming Client",
	"application/vnd.irepository.package+xml": "iRepository / Lucidoc Editor",
	"text/vnd.sun.j2me.app-descriptor": "J2ME App Descriptor",
	"application/java-archive": "Java Archive",
	"application/java-vm": "Java Bytecode File",
	"application/x-java-jnlp-file": "Java Network Launching Protocol",
	"application/java-serialized-object": "Java Serialized Object",
	"text/x-java-source,java": "Java Source File",
	"application/javascript": "JavaScript",
	"text/javascript": "JavaScript Module",
	"application/json": "JavaScript Object Notation (JSON)",
	"application/vnd.joost.joda-archive": "Joda Archive",
	"video/jpm": "JPEG 2000 Compound Image File Format",
	"image/jpeg": "JPEG Image",
	"image/x-citrix-jpeg": "JPEG Image (Citrix client)",
	"image/pjpeg": "JPEG Image (Progressive)",
	"video/jpeg": "JPGVideo",
	"application/ld+json": "JSON - Linked Data",
	"application/vnd.kahootz": "Kahootz",
	"application/vnd.chipnuts.karaoke-mmd": "Karaoke on Chipnuts Chipsets",
	"application/vnd.kde.karbon": "KDE KOffice Office Suite - Karbon",
	"application/vnd.kde.kchart": "KDE KOffice Office Suite - KChart",
	"application/vnd.kde.kformula": "KDE KOffice Office Suite - Kformula",
	"application/vnd.kde.kivio": "KDE KOffice Office Suite - Kivio",
	"application/vnd.kde.kontour": "KDE KOffice Office Suite - Kontour",
	"application/vnd.kde.kpresenter": "KDE KOffice Office Suite - Kpresenter",
	"application/vnd.kde.kspread": "KDE KOffice Office Suite - Kspread",
	"application/vnd.kde.kword": "KDE KOffice Office Suite - Kword",
	"application/vnd.kenameaapp": "Kenamea App",
	"application/vnd.kidspiration": "Kidspiration",
	"application/vnd.kinar": "Kinar Applications",
	"application/vnd.kodak-descriptor": "Kodak Storyshare",
	"application/vnd.las.las+xml": "Laser App Enterprise",
	"application/x-latex": "LaTeX",
	"application/vnd.llamagraphics.life-balance.desktop": "Life Balance - Desktop Edition",
	"application/vnd.llamagraphics.life-balance.exchange+xml": "Life Balance - Exchange Format",
	"application/vnd.jam": "Lightspeed Audio Lab",
	"application/vnd.lotus-1-2-3": "Lotus 1-2-3",
	"application/vnd.lotus-approach": "Lotus Approach",
	"application/vnd.lotus-freelance": "Lotus Freelance",
	"application/vnd.lotus-notes": "Lotus Notes",
	"application/vnd.lotus-organizer": "Lotus Organizer",
	"application/vnd.lotus-screencam": "Lotus Screencam",
	"application/vnd.lotus-wordpro": "Lotus Wordpro",
	"audio/vnd.lucent.voice": "Lucent Voice",
	"audio/x-mpegurl": "M3U (Multimedia Playlist)",
	"video/x-m4v": "M4v",
	"application/mac-binhex40": "Macintosh BinHex 4.0",
	"application/vnd.macports.portpkg": "MacPorts Port System",
	"application/vnd.osgeo.mapguide.package": "MapGuide DBXML",
	"application/marc": "MARC Formats",
	"application/marcxml+xml": "MARC21 XML Schema",
	"application/mxf": "Material Exchange Format",
	"application/vnd.wolfram.player": "Mathematica Notebook Player",
	"application/mathematica": "Mathematica Notebooks",
	"application/mathml+xml": "Mathematical Markup Language",
	"application/mbox": "Mbox database files",
	"application/vnd.medcalcdata": "MedCalc",
	"application/mediaservercontrol+xml": "Media Server Control Markup Language",
	"application/vnd.mediastation.cdkey": "MediaRemote",
	"application/vnd.mfer": "Medical Waveform Encoding Format",
	"application/vnd.mfmp": "Melody Format for Mobile Platform",
	"model/mesh": "Mesh Data Type",
	"application/mads+xml": "Metadata Authority Description Schema",
	"application/mets+xml": "Metadata Encoding and Transmission Standard",
	"application/mods+xml": "Metadata Object Description Schema",
	"application/metalink4+xml": "Metalink",
	"application/vnd.mcd": "Micro CADAM Helix D&D",
	"application/vnd.micrografx.flo": "Micrografx",
	"application/vnd.micrografx.igx": "Micrografx iGrafx Professional",
	"application/vnd.eszigno3+xml": "MICROSEC e-Szign�",
	"application/x-msaccess": "Microsoft Access",
	"video/x-ms-asf": "Microsoft Advanced Systems Format (ASF)",
	"application/x-msdownload": "Microsoft Application",
	"application/vnd.ms-artgalry": "Microsoft Artgalry",
	"application/vnd.ms-cab-compressed": "Microsoft Cabinet File",
	"application/vnd.ms-ims": "Microsoft Class Server",
	"application/x-ms-application": "Microsoft ClickOnce",
	"application/x-msclip": "Microsoft Clipboard Clip",
	"image/vnd.ms-modi": "Microsoft Document Imaging Format",
	"application/vnd.ms-fontobject": "Microsoft Embedded OpenType",
	"application/vnd.ms-excel": "Microsoft Excel",
	"application/vnd.ms-excel.addin.macroenabled.12": "Microsoft Excel - Add-In File",
	"application/vnd.ms-excel.sheet.binary.macroenabled.12": "Microsoft Excel - Binary Workbook",
	"application/vnd.ms-excel.template.macroenabled.12": "Microsoft Excel - Macro-Enabled Template File",
	"application/vnd.ms-excel.sheet.macroenabled.12": "Microsoft Excel - Macro-Enabled Workbook",
	"application/vnd.ms-htmlhelp": "Microsoft Html Help File",
	"application/x-mscardfile": "Microsoft Information Card",
	"application/vnd.ms-lrm": "Microsoft Learning Resource Module",
	"application/x-msmediaview": "Microsoft MediaView",
	"application/x-msmoney": "Microsoft Money",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation": "Microsoft Office - OOXML - Presentation",
	"application/vnd.openxmlformats-officedocument.presentationml.slide": "Microsoft Office - OOXML - Presentation (Slide)",
	"application/vnd.openxmlformats-officedocument.presentationml.slideshow": "Microsoft Office - OOXML - Presentation (Slideshow)",
	"application/vnd.openxmlformats-officedocument.presentationml.template": "Microsoft Office - OOXML - Presentation Template",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "Microsoft Office - OOXML - Spreadsheet",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.template": "Microsoft Office - OOXML - Spreadsheet Template",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document": "Microsoft Office - OOXML - Word Document",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.template": "Microsoft Office - OOXML - Word Document Template",
	"application/x-msbinder": "Microsoft Office Binder",
	"application/vnd.ms-officetheme": "Microsoft Office System Release Theme",
	"application/onenote": "Microsoft OneNote",
	"audio/vnd.ms-playready.media.pya": "Microsoft PlayReady Ecosystem",
	"video/vnd.ms-playready.media.pyv": "Microsoft PlayReady Ecosystem Video",
	"application/vnd.ms-powerpoint": "Microsoft PowerPoint",
	"application/vnd.ms-powerpoint.addin.macroenabled.12": "Microsoft PowerPoint - Add-in file",
	"application/vnd.ms-powerpoint.slide.macroenabled.12": "Microsoft PowerPoint - Macro-Enabled Open XML Slide",
	"application/vnd.ms-powerpoint.presentation.macroenabled.12": "Microsoft PowerPoint - Macro-Enabled Presentation File",
	"application/vnd.ms-powerpoint.slideshow.macroenabled.12": "Microsoft PowerPoint - Macro-Enabled Slide Show File",
	"application/vnd.ms-powerpoint.template.macroenabled.12": "Microsoft PowerPoint - Macro-Enabled Template File",
	"application/vnd.ms-project": "Microsoft Project",
	"application/x-mspublisher": "Microsoft Publisher",
	"application/x-msschedule": "Microsoft Schedule+",
	"application/x-silverlight-app": "Microsoft Silverlight",
	"application/vnd.ms-pki.stl": "Microsoft Trust UI Provider - Certificate Trust Link",
	"application/vnd.ms-pki.seccat": "Microsoft Trust UI Provider - Security Catalog",
	"application/vnd.visio": "Microsoft Visio",
	"application/vnd.visio2013": "Microsoft Visio 2013",
	"video/x-ms-wm": "Microsoft Windows Media",
	"audio/x-ms-wma": "Microsoft Windows Media Audio",
	"audio/x-ms-wax": "Microsoft Windows Media Audio Redirector",
	"video/x-ms-wmx": "Microsoft Windows Media Audio/Video Playlist",
	"application/x-ms-wmd": "Microsoft Windows Media Player Download Package",
	"application/vnd.ms-wpl": "Microsoft Windows Media Player Playlist",
	"application/x-ms-wmz": "Microsoft Windows Media Player Skin Package",
	"video/x-ms-wmv": "Microsoft Windows Media Video",
	"video/x-ms-wvx": "Microsoft Windows Media Video Playlist",
	"application/x-msmetafile": "Microsoft Windows Metafile",
	"application/x-msterminal": "Microsoft Windows Terminal Services",
	"application/msword": "Microsoft Word",
	"application/vnd.ms-word.document.macroenabled.12": "Microsoft Word - Macro-Enabled Document",
	"application/vnd.ms-word.template.macroenabled.12": "Microsoft Word - Macro-Enabled Template",
	"application/x-mswrite": "Microsoft Wordpad",
	"application/vnd.ms-works": "Microsoft Works",
	"application/x-ms-xbap": "Microsoft XAML Browser Application",
	"application/vnd.ms-xpsdocument": "Microsoft XML Paper Specification",
	"audio/midi": "MIDI - Musical Instrument Digital Interface",
	"application/vnd.ibm.minipay": "MiniPay",
	"application/vnd.ibm.modcap": "MO:DCA-P",
	"application/vnd.jcp.javame.midlet-rms": "Mobile Information Device Profile",
	"application/vnd.tmobile-livetv": "MobileTV",
	"application/x-mobipocket-ebook": "Mobipocket",
	"application/vnd.mobius.mbk": "Mobius Management Systems - Basket file",
	"application/vnd.mobius.dis": "Mobius Management Systems - Distribution Database",
	"application/vnd.mobius.plc": "Mobius Management Systems - Policy Definition Language File",
	"application/vnd.mobius.mqy": "Mobius Management Systems - Query File",
	"application/vnd.mobius.msl": "Mobius Management Systems - Script Language",
	"application/vnd.mobius.txf": "Mobius Management Systems - Topic Index File",
	"application/vnd.mobius.daf": "Mobius Management Systems - UniversalArchive",
	"text/vnd.fly": "mod_fly / fly.cgi",
	"application/vnd.mophun.certificate": "Mophun Certificate",
	"application/vnd.mophun.application": "Mophun VM",
	"video/mj2": "Motion JPEG 2000",
	"audio/mpeg": "MPEG Audio",
	"video/mp2t": "MPEG Transport Stream",
	"video/vnd.mpegurl": "MPEG Url",
	"video/mpeg": "MPEG Video",
	"application/mp21": "MPEG-21",
	"audio/mp4": "MPEG-4 Audio",
	"video/mp4": "MPEG-4 Video",
	"application/mp4": "MPEG4",
	"application/vnd.apple.mpegurl": "Multimedia Playlist Unicode",
	"application/vnd.musician": "MUsical Score Interpreted Code Invented for the ASCII designation of Notation",
	"application/vnd.muvee.style": "Muvee Automatic Video Editing",
	"application/xv+xml": "MXML",
	"application/vnd.nokia.n-gage.data": "N-Gage Game Data",
	"application/vnd.nokia.n-gage.symbian.install": "N-Gage Game Installer",
	"application/x-dtbncx+xml": "Navigation Control file for XML (for ePub)",
	"application/x-netcdf": "Network Common Data Form (NetCDF)",
	"application/vnd.neurolanguage.nlu": "neuroLanguage",
	"application/vnd.dna": "New Moon Liftoff/DNA",
	"application/vnd.noblenet-directory": "NobleNet Directory",
	"application/vnd.noblenet-sealer": "NobleNet Sealer",
	"application/vnd.noblenet-web": "NobleNet Web",
	"application/vnd.nokia.radio-preset": "Nokia Radio Application - Preset",
	"application/vnd.nokia.radio-presets": "Nokia Radio Application - Preset",
	"text/n3": "Notation3",
	"application/vnd.novadigm.edm": "Novadigm's RADIA and EDM products",
	"application/vnd.novadigm.edx": "Novadigm's RADIA and EDM products",
	"application/vnd.novadigm.ext": "Novadigm's RADIA and EDM products",
	"application/vnd.flographit": "NpGraphIt",
	"audio/vnd.nuera.ecelp4800": "Nuera ECELP 4800",
	"audio/vnd.nuera.ecelp7470": "Nuera ECELP 7470",
	"audio/vnd.nuera.ecelp9600": "Nuera ECELP 9600",
	"application/oda": "Office Document Architecture",
	"application/ogg": "Ogg",
	"audio/ogg": "Ogg Audio",
	"video/ogg": "Ogg Video",
	"application/vnd.oma.dd2+xml": "OMA Download Agents",
	"application/vnd.oasis.opendocument.text-web": "Open Document Text Web",
	"application/oebps-package+xml": "Open eBook Publication Structure",
	"application/vnd.intu.qbo": "Open Financial Exchange",
	"application/vnd.openofficeorg.extension": "Open Office Extension",
	"application/vnd.yamaha.openscoreformat": "Open Score Format",
	"audio/webm": "Open Web Media Project - Audio",
	"video/webm": "Open Web Media Project - Video",
	"application/vnd.oasis.opendocument.chart": "OpenDocument Chart",
	"application/vnd.oasis.opendocument.chart-template": "OpenDocument Chart Template",
	"application/vnd.oasis.opendocument.database": "OpenDocument Database",
	"application/vnd.oasis.opendocument.formula": "OpenDocument Formula",
	"application/vnd.oasis.opendocument.formula-template": "OpenDocument Formula Template",
	"application/vnd.oasis.opendocument.graphics": "OpenDocument Graphics",
	"application/vnd.oasis.opendocument.graphics-template": "OpenDocument Graphics Template",
	"application/vnd.oasis.opendocument.image": "OpenDocument Image",
	"application/vnd.oasis.opendocument.image-template": "OpenDocument Image Template",
	"application/vnd.oasis.opendocument.presentation": "OpenDocument Presentation",
	"application/vnd.oasis.opendocument.presentation-template": "OpenDocument Presentation Template",
	"application/vnd.oasis.opendocument.spreadsheet": "OpenDocument Spreadsheet",
	"application/vnd.oasis.opendocument.spreadsheet-template": "OpenDocument Spreadsheet Template",
	"application/vnd.oasis.opendocument.text": "OpenDocument Text",
	"application/vnd.oasis.opendocument.text-master": "OpenDocument Text Master",
	"application/vnd.oasis.opendocument.text-template": "OpenDocument Text Template",
	"image/ktx": "OpenGL Textures (KTX)",
	"application/vnd.sun.xml.calc": "OpenOffice - Calc (Spreadsheet)",
	"application/vnd.sun.xml.calc.template": "OpenOffice - Calc Template (Spreadsheet)",
	"application/vnd.sun.xml.draw": "OpenOffice - Draw (Graphics)",
	"application/vnd.sun.xml.draw.template": "OpenOffice - Draw Template (Graphics)",
	"application/vnd.sun.xml.impress": "OpenOffice - Impress (Presentation)",
	"application/vnd.sun.xml.impress.template": "OpenOffice - Impress Template (Presentation)",
	"application/vnd.sun.xml.math": "OpenOffice - Math (Formula)",
	"application/vnd.sun.xml.writer": "OpenOffice - Writer (Text - HTML)",
	"application/vnd.sun.xml.writer.global": "OpenOffice - Writer (Text - HTML)",
	"application/vnd.sun.xml.writer.template": "OpenOffice - Writer Template (Text - HTML)",
	"application/x-font-otf": "OpenType Font File",
	"audio/opus": "Opus Audio",
	"application/vnd.yamaha.openscoreformat.osfpvg+xml": "OSFPVG",
	"application/vnd.osgi.dp": "OSGi Deployment Package",
	"application/vnd.palm": "PalmOS Data",
	"text/x-pascal": "Pascal Source File",
	"application/vnd.pawaafile": "PawaaFILE",
	"application/vnd.hp-pclxl": "PCL 6 Enhanced (Formely PCL XL)",
	"application/vnd.picsel": "Pcsel eFIF File",
	"image/x-pcx": "PCX Image",
	"image/vnd.adobe.photoshop": "Photoshop Document",
	"application/pics-rules": "PICSRules",
	"image/x-pict": "PICT Image",
	"application/x-chat": "pIRCh",
	"application/pkcs10": "PKCS #10 - Certification Request Standard",
	"application/x-pkcs12": "PKCS #12 - Personal Information Exchange Syntax Standard",
	"application/pkcs7-mime": "PKCS #7 - Cryptographic Message Syntax Standard",
	"application/pkcs7-signature": "PKCS #7 - Cryptographic Message Syntax Standard",
	"application/x-pkcs7-certreqresp": "PKCS #7 - Cryptographic Message Syntax Standard (Certificate Request Response)",
	"application/x-pkcs7-certificates": "PKCS #7 - Cryptographic Message Syntax Standard (Certificates)",
	"application/pkcs8": "PKCS #8 - Private-Key Information Syntax Standard",
	"application/vnd.pocketlearn": "PocketLearn Viewers",
	"image/x-portable-anymap": "Portable Anymap Image",
	"image/x-portable-bitmap": "Portable Bitmap Format",
	"application/x-font-pcf": "Portable Compiled Format",
	"application/font-tdpfr": "Portable Font Resource",
	"application/x-chess-pgn": "Portable Game Notation (Chess Games)",
	"image/x-portable-graymap": "Portable Graymap Format",
	"image/png": "Portable Network Graphics (PNG)",
	"image/x-citrix-png": "Portable Network Graphics (PNG) (Citrix client)",
	"image/x-png": "Portable Network Graphics (PNG) (x-token)",
	"image/x-portable-pixmap": "Portable Pixmap Format",
	"application/pskc+xml": "Portable Symmetric Key Container",
	"application/vnd.ctc-posml": "PosML",
	"application/postscript": "PostScript",
	"application/x-font-type1": "PostScript Fonts",
	"application/vnd.powerbuilder6": "PowerBuilder",
	"application/pgp-encrypted": "Pretty Good Privacy",
	"application/pgp-signature": "Pretty Good Privacy - Signature",
	"application/vnd.previewsystems.box": "Preview Systems ZipLock/VBox",
	"application/vnd.pvi.ptid1": "Princeton Video Image",
	"application/pls+xml": "Pronunciation Lexicon Specification",
	"application/vnd.pg.format": "Proprietary P&G Standard Reporting System",
	"application/vnd.pg.osasli": "Proprietary P&G Standard Reporting System",
	"text/prs.lines.tag": "PRS Lines Tag",
	"application/x-font-linux-psf": "PSF Fonts",
	"application/vnd.publishare-delta-tree": "PubliShare Objects",
	"application/vnd.pmi.widget": "Qualcomm's Plaza Mobile Internet",
	"application/vnd.quark.quarkxpress": "QuarkXpress",
	"application/vnd.epson.esf": "QUASS Stream Player",
	"application/vnd.epson.msf": "QUASS Stream Player",
	"application/vnd.epson.ssf": "QUASS Stream Player",
	"application/vnd.epson.quickanime": "QuickAnime Player",
	"application/vnd.intu.qfx": "Quicken",
	"video/quicktime": "Quicktime Video",
	"application/x-rar-compressed": "RAR Archive",
	"audio/x-pn-realaudio": "Real Audio Sound",
	"audio/x-pn-realaudio-plugin": "Real Audio Sound",
	"application/rsd+xml": "Really Simple Discovery",
	"application/vnd.rn-realmedia": "RealMedia",
	"application/vnd.realvnc.bed": "RealVNC",
	"application/vnd.recordare.musicxml": "Recordare Applications",
	"application/vnd.recordare.musicxml+xml": "Recordare Applications",
	"application/relax-ng-compact-syntax": "Relax NG Compact Syntax",
	"application/vnd.data-vision.rdz": "RemoteDocs R-Viewer",
	"application/rdf+xml": "Resource Description Framework",
	"application/vnd.cloanto.rp9": "RetroPlatform Player",
	"application/vnd.jisp": "RhymBox",
	"application/rtf": "Rich Text Format",
	"text/richtext": "Rich Text Format (RTF)",
	"application/vnd.route66.link66+xml": "ROUTE 66 Location Based Services",
	"application/rss+xml": "RSS - Really Simple Syndication",
	"application/shf+xml": "S Hexdump Format",
	"application/vnd.sailingtracker.track": "SailingTracker",
	"image/svg+xml": "Scalable Vector Graphics (SVG)",
	"application/vnd.sus-calendar": "ScheduleUs",
	"application/sru+xml": "Search/Retrieve via URL Response Format",
	"application/set-payment-initiation": "Secure Electronic Transaction - Payment",
	"application/set-registration-initiation": "Secure Electronic Transaction - Registration",
	"application/vnd.sema": "Secured eMail",
	"application/vnd.semd": "Secured eMail",
	"application/vnd.semf": "Secured eMail",
	"application/vnd.seemail": "SeeMail",
	"application/x-font-snf": "Server Normal Format",
	"application/scvp-vp-request": "Server-Based Certificate Validation Protocol - Validation Policies - Request",
	"application/scvp-vp-response": "Server-Based Certificate Validation Protocol - Validation Policies - Response",
	"application/scvp-cv-request": "Server-Based Certificate Validation Protocol - Validation Request",
	"application/scvp-cv-response": "Server-Based Certificate Validation Protocol - Validation Response",
	"application/sdp": "Session Description Protocol",
	"text/x-setext": "Setext",
	"video/x-sgi-movie": "SGI Movie",
	"application/vnd.shana.informed.formdata": "Shana Informed Filler",
	"application/vnd.shana.informed.formtemplate": "Shana Informed Filler",
	"application/vnd.shana.informed.interchange": "Shana Informed Filler",
	"application/vnd.shana.informed.package": "Shana Informed Filler",
	"application/thraud+xml": "Sharing Transaction Fraud Data",
	"application/x-shar": "Shell Archive",
	"image/x-rgb": "Silicon Graphics RGB Bitmap",
	"application/vnd.epson.salt": "SimpleAnimeLite Player",
	"application/vnd.accpac.simply.aso": "Simply Accounting",
	"application/vnd.accpac.simply.imp": "Simply Accounting - Data Import",
	"application/vnd.simtech-mindmapper": "SimTech MindMapper",
	"application/vnd.commonspace": "Sixth Floor Media - CommonSpace",
	"application/vnd.yamaha.smaf-audio": "SMAF Audio",
	"application/vnd.smaf": "SMAF File",
	"application/vnd.yamaha.smaf-phrase": "SMAF Phrase",
	"application/vnd.smart.teacher": "SMART Technologies Apps",
	"application/vnd.svd": "SourceView Document",
	"application/sparql-query": "SPARQL - Query",
	"application/sparql-results+xml": "SPARQL - Results",
	"application/srgs": "Speech Recognition Grammar Specification",
	"application/srgs+xml": "Speech Recognition Grammar Specification - XML",
	"application/ssml+xml": "Speech Synthesis Markup Language",
	"application/vnd.koan": "SSEYO Koan Play File",
	"text/sgml": "Standard Generalized Markup Language (SGML)",
	"application/vnd.stardivision.calc": "StarOffice - Calc",
	"application/vnd.stardivision.draw": "StarOffice - Draw",
	"application/vnd.stardivision.impress": "StarOffice - Impress",
	"application/vnd.stardivision.math": "StarOffice - Math",
	"application/vnd.stardivision.writer": "StarOffice - Writer",
	"application/vnd.stardivision.writer-global": "StarOffice - Writer (Global)",
	"application/vnd.stepmania.stepchart": "StepMania",
	"application/x-stuffit": "Stuffit Archive",
	"application/x-stuffitx": "Stuffit Archive",
	"application/vnd.solent.sdkm+xml": "SudokuMagic",
	"application/vnd.olpc-sugar": "Sugar Linux Application Bundle",
	"audio/basic": "Sun Audio - Au file format",
	"application/vnd.wqd": "SundaHus WQ",
	"application/vnd.symbian.install": "Symbian Install Package",
	"application/smil+xml": "Synchronized Multimedia Integration Language",
	"application/vnd.syncml+xml": "SyncML",
	"application/vnd.syncml.dm+wbxml": "SyncML - Device Management",
	"application/vnd.syncml.dm+xml": "SyncML - Device Management",
	"application/x-sv4cpio": "System V Release 4 CPIO Archive",
	"application/x-sv4crc": "System V Release 4 CPIO Checksum Data",
	"application/sbml+xml": "Systems Biology Markup Language",
	"text/tab-separated-values": "Tab Seperated Values",
	"image/tiff": "Tagged Image File Format",
	"application/vnd.tao.intent-module-archive": "Tao Intent",
	"application/x-tar": "Tar File (Tape Archive)",
	"application/x-tcl": "Tcl Script",
	"application/x-tex": "TeX",
	"application/x-tex-tfm": "TeX Font Metric",
	"application/tei+xml": "Text Encoding and Interchange",
	"text/plain": "Text File",
	"application/vnd.spotfire.dxp": "TIBCO Spotfire",
	"application/vnd.spotfire.sfs": "TIBCO Spotfire",
	"application/timestamped-data": "Time Stamped Data Envelope",
	"application/vnd.trid.tpt": "TRI Systems Config",
	"application/vnd.triscape.mxs": "Triscape Map Explorer",
	"text/troff": "troff",
	"application/vnd.trueapp": "True BASIC",
	"application/x-font-ttf": "TrueType Font",
	"text/turtle": "Turtle (Terse RDF Triple Language)",
	"application/vnd.umajin": "UMAJIN",
	"application/vnd.uoml+xml": "Unique Object Markup Language",
	"application/vnd.unity": "Unity 3d",
	"application/vnd.ufdl": "Universal Forms Description Language",
	"text/uri-list": "URI Resolution Services",
	"application/vnd.uiq.theme": "User Interface Quartz - Theme (Symbian)",
	"application/x-ustar": "Ustar (Uniform Standard Tape Archive)",
	"text/x-uuencode": "UUEncode",
	"text/x-vcalendar": "vCalendar",
	"text/x-vcard": "vCard",
	"application/x-cdlink": "Video CD",
	"application/vnd.vsf": "Viewport+",
	"model/vrml": "Virtual Reality Modeling Language",
	"application/vnd.vcx": "VirtualCatalog",
	"model/vnd.mts": "Virtue MTS",
	"model/vnd.vtu": "Virtue VTU",
	"application/vnd.visionary": "Visionary",
	"video/vnd.vivo": "Vivo",
	"application/ccxml+xml,": "Voice Browser Call Control",
	"application/voicexml+xml": "VoiceXML",
	"application/x-wais-source": "WAIS Source",
	"application/vnd.wap.wbxml": "WAP Binary XML (WBXML)",
	"image/vnd.wap.wbmp": "WAP Bitamp (WBMP)",
	"audio/x-wav{{}}": "Waveform Audio File Format (WAV)",
	"application/davmount+xml": "Web Distributed Authoring and Versioning",
	"application/x-font-woff": "Web Open Font Format",
	"application/wspolicy+xml": "Web Services Policy",
	"image/webp": "WebP Image",
	"application/vnd.webturbo": "WebTurbo",
	"application/widget": "Widget Packaging and XML Configuration",
	"application/winhlp": "WinHelp",
	"text/vnd.wap.wml": "Wireless Markup Language (WML)",
	"text/vnd.wap.wmlscript": "Wireless Markup Language Script (WMLScript)",
	"application/vnd.wap.wmlscriptc": "WMLScript",
	"application/vnd.wordperfect": "Wordperfect",
	"application/vnd.wt.stf": "Worldtalk",
	"application/wsdl+xml": "WSDL - Web Services Description Language",
	"image/x-xbitmap": "X BitMap",
	"image/x-xpixmap": "X PixMap",
	"image/x-xwindowdump": "X Window Dump",
	"application/x-x509-ca-cert": "X.509 Certificate",
	"application/x-xfig": "Xfig",
	"application/xhtml+xml": "XHTML - The Extensible HyperText Markup Language",
	"application/xml": "XML - Extensible Markup Language",
	"application/xcap-diff+xml": "XML Configuration Access Protocol - XCAP Diff",
	"application/xenc+xml": "XML Encryption Syntax and Processing",
	"application/patch-ops-error+xml": "XML Patch Framework",
	"application/resource-lists+xml": "XML Resource Lists",
	"application/rls-services+xml": "XML Resource Lists",
	"application/resource-lists-diff+xml": "XML Resource Lists Diff",
	"application/xslt+xml": "XML Transformations",
	"application/xop+xml": "XML-Binary Optimized Packaging",
	"application/x-xpinstall": "XPInstall - Mozilla",
	"application/xspf+xml": "XSPF - XML Shareable Playlist Format",
	"application/vnd.mozilla.xul+xml": "XUL - XML User Interface Language",
	"chemical/x-xyz": "XYZ File Format",
	"text/yaml": "YAML Ain't Markup Language / Yet Another Markup Language",
	"application/yang": "YANG Data Modeling Language",
	"application/yin+xml": "YIN (YANG - XML)",
	"application/vnd.zul": "Z.U.L. Geometry",
	"application/zip": "Zip Archive",
	"application/vnd.handheld-entertainment+xml": "ZVUE Media Manager",
	"application/vnd.zzazz.deck+xml": "Zzazz Deck"
};

export default getIconFromType;
