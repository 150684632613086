const EmptyMenu = () => {
  return (
    <>
      <div className='info-menu-empty'>
        <center>
          <i className="fa fa-cube info-menu-empty-icon" /><br />
          Select an item to see the details
        </center>
      </div>

    </>
  )
}

export default EmptyMenu;