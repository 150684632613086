import './style.css';

export default () => {
  return (
    <center>
      <div className='spinner-wrapper'>
        <div className="spinner-loader" />
      </div>
    </center>
  )
};